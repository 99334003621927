@import "~bootstrap/scss/bootstrap";
@import "amj-font";

$font__body: "AMJ1112", "Helvetica Neue", sans-serif;

* {
	font-size: 0.95em;
	font-family: $font__body;
	line-height: 1.1em;
	text-transform: uppercase;
	letter-spacing: 0.025em;

	@media (min-width: 1400px) {
		font-size: 0.975em;
	}
}

html, body, .container-fluid {
	height: 100%;
}

h1 {
	font-size: 1.15rem;

	@media (min-width: 1400px) {
		font-size: 1.35rem;
	}
}

a {
	color: black;
	text-decoration: none;

	&:hover {
		color: black;
		text-decoration: underline;
	}
}

.bespoke-br {
	display: none;

	@media (min-width: 480px) {
		display: block;
	}

	&-mobile {
		display: block;

		@media (min-width: 480px) {
			display: none;
		}
	}
}

.title {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.col--first {
	max-width: 250px;

	@media (min-width: 1400px) {
		max-width: 275px;
	}
}

.img_bg {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
	height: 75%;
	width: 100%;
	object-fit: contain;
	object-position: bottom right;

	@media (orientation: landscape) {
		height: 100%;
	}
}

/* Plugin looks for elements with class named "balance-text" */
.balance-text {
	text-wrap: balance;  /* Apply (proposed) CSS style */
}